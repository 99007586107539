<template>
  <div>
    <div v-if="active==0">
      <el-divider>设备实时数据</el-divider>
      <el-row type="flex" justify="space-around">
        <el-col span="11">
          <el-card :body-style="{ padding: '10px',display:'flex'}" class="card">
            <el-image style="width: 48px; height: 48px" src="./images/icons/thermometer.png"></el-image>
            <div style="flex:1;display:flex;flex-direction:column;justify-content: space-between;">
              <div class="cardinfo">温度</div>
              <div class="carddata">3℃</div>
            </div>
          </el-card>
        </el-col>
        <el-col span="11">
          <el-card :body-style="{ padding: '10px',display:'flex'}" class="card">
            <el-image style="width: 48px; height: 48px" src="./images/icons/inbox-in.png"></el-image>
            <div style="flex:1;display:flex;flex-direction:column;justify-content: space-between;">
              <div class="cardinfo">位移</div>
              <div class="carddata">3℃</div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-around" style="margin-top:10px;">
        <el-col span="11">
          <el-card :body-style="{ padding: '10px',display:'flex'}" class="card">
            <el-image style="width: 48px; height: 48px" src="./images/icons/timer.png"></el-image>
            <div style="flex:1;display:flex;flex-direction:column;justify-content: space-between;">
              <div class="cardinfo">下落时间</div>
              <div class="carddata">3℃</div>
            </div>
          </el-card>
        </el-col>
        <el-col span="11">
          <el-button type="primary" style="width:100%;height:100%;font-size: 20px;font-weight: bolder;" :round="true">开始试验</el-button>
        </el-col>
      </el-row>
      <el-divider>试验结果</el-divider>
    </div>
    <div v-else-if="active==1">2</div>
    <div v-else-if="active==2">3</div>
    <van-tabbar v-model="active" @change="handleChange">
      <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
      <van-tabbar-item icon="search">查询</van-tabbar-item>
      <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
  props: ["serialNumber"],
  data() {
    return {
      active: 0,
      loading: null,
    };
  },
  methods: {
    handleChange(a) {
      let that = this;
      that.loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
      });
      setTimeout(() => {
        that.loading.close();
      }, 200);
      console.log(a);
    },
  },
  mounted() {
    let that = this;
    const wsuri = process.env.VUE_APP_WebSocketURL;
    that.websock = new WebSocket(wsuri);
    that.websock.onmessage = function (e) {
      //console.log(e);
      //数据接收
      if (e.data != 0) {
        that.deviceData32 = JSON.parse(e.data);
      } else {
        that.deviceData32 = e.data;
      }

      setTimeout(() => {
        that.websock.send(
          JSON.stringify({
            deviceType: "ZRD008",
            serialNumber: that.serialNumber,
            command: "32",
          })
        );
      }, 200);
    };
    that.websock.onopen = function () {
      that.websock.send(
        JSON.stringify({
          deviceType: "ZRD001",
          serialNumber: that.serialNumber,
          command: "32",
        })
      );
    };
    that.websock.onerror = function () {};
    that.websock.onclose = function () {};
  },
};
</script>

<style scoped>
.card {
  border: solid 1px #1b88ee;
}
.cardinfo {
  display: flex;
  flex-direction: row-reverse;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.carddata {
  display: flex;
  flex-direction: row-reverse;
  font-weight: bolder;
  font-size: 20px;
  color: red;
}
</style>